import moment from "moment";
import { useState } from "react";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
// import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { Modal } from "react-bootstrap";
import { checkProvisioningFromPartnerInfra } from "../../api/projects";
import Pagination from "../../components/Pagination";
import { useProvisioningPartnerHooks } from "../../utils/infrastructure/ProvisioningUtils";

const statusProvisioningArr = ["On Process", "Success", "Rejected"];

export default function ProvisioningPartnerPage() {
  // const { hasAccess } = useControlPermission();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [formFilter, setFormFilter] = useState({
    q: "",
    status: "",
    type: "",
  });

  let [filter, setFilter] = useState({ q: "", status: "", type: "", page: 1, page_size: 20 });
  const [modal, setModal] = useState(null);
  const [selected, setSelected] = useState({});

  const selectModal = (modalName, selectedData) => {
    setModal(modalName);
    setSelected(selectedData);
  };

  const { data: provisionings, meta, loading, errorMsg } = useProvisioningPartnerHooks({ filter });

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message: message, type: "success" });
    setFilter({ ...filter });
  };

  const onError = (message) => {
    setAlert({ show: true, message: message, type: "danger" });
    setFilter({ ...filter });
  };

  const applyFilter = () => {
    setFilter({
      ...filter,
      ...formFilter,
      page: 1,
    });
  };

  const resetFilter = () => {
    setFormFilter({
      q: "",
      status: "",
      type: "",
    });
    setFilter({
      q: "",
      status: "",
      type: "",
      page: 1,
      page_size: 20,
    });
  };

  return (
    <MainLayout resource_key="partner-infrastructure">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Daftar Provisioning</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Box title={`Provisioning Partner (Total: ${meta.count})`} loading={loading}>
              <div className="row">
                <div className="col-12 col-md-2">
                  <div className="form-group">
                    <label>Pencarian</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="No. Reg / No. Customer / Nama"
                      value={formFilter.q}
                      onChange={(event) => setFormFilter({ ...formFilter, q: event.target.value })}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-2">
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-control"
                      value={formFilter.status}
                      onChange={(event) =>
                        setFormFilter({ ...formFilter, status: event.target.value })
                      }
                    >
                      <option value="">Semua</option>
                      {statusProvisioningArr.map((status, index) => (
                        <option key={index} value={index}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-2">
                  <div className="form-group">
                    <label>Tipe</label>
                    <select
                      className="form-control"
                      value={formFilter.type}
                      onChange={(event) =>
                        setFormFilter({ ...formFilter, type: event.target.value })
                      }
                    >
                      <option value="">Semua</option>
                      <option value="activation">Activation</option>
                      <option value="change-modem">Change Modem</option>
                      <option value="terminate">Terminate</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm">
                  <button
                    className="btn btn-primary"
                    onClick={() => applyFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Filter
                  </button>
                  <button
                    className="btn btn-default"
                    onClick={() => resetFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Reset
                  </button>
                </div>
              </div>

              {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
              <AlertBox {...alert} setAlert={setAlert} />
              <div className="auto-horizontal-scroll">
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th></th>
                      <th>No. Reg</th>
                      <th>Nama Pelanggan</th>
                      <th className="text-nowrap">Provisioning ID</th>
                      <th className="text-nowrap">Service ID</th>
                      <th className="text-nowrap">SN Modem</th>
                      <th className="text-nowrap">SN GPON</th>
                      <th className="text-nowrap">Status</th>
                      <th className="text-nowrap">Type</th>
                      <th className="text-nowrap">Waktu Request</th>
                      <th className="text-nowrap">Waktu Selesai</th>
                      <th className="text-nowrap">Partner</th>
                    </tr>
                  </thead>
                  <tbody>
                    {provisionings.length === 0 ? (
                      <tr>
                        <td colSpan={12} className="text-center">
                          Tidak ada data
                        </td>
                      </tr>
                    ) : null}
                    {provisionings.map((provisioning) => (
                      <tr key={provisioning.id}>
                        <td style={{ width: "150px" }}>
                          <button
                            className="btn btn-sm btn-block btn-primary text-nowrap"
                            onClick={() => selectModal("detail", provisioning)}
                          >
                            <i className="fa fa-eye"></i> Detail
                          </button>
                          {provisioning.status === 0 && (
                            <CheckProvisioningBtn
                              id={provisioning.id}
                              onSuccess={onSuccess}
                              onError={onError}
                            />
                          )}
                        </td>
                        <td>{provisioning.registration?.registration_number || "-"}</td>
                        <td>{provisioning.registration?.name || "-"}</td>
                        <td>{provisioning.partner_provisioning_id}</td>
                        <td>{provisioning.partner_service_id}</td>
                        <td>{provisioning.modem?.serial_number_modem || "-"}</td>
                        <td>{provisioning.modem?.serial_number_gpon || "-"}</td>
                        <td>
                          <ProvisioningStatusBadge status={provisioning.status} />
                        </td>
                        <td>{provisioning.type || "-"}</td>
                        <td className="text-nowrap">
                          {provisioning.request_date
                            ? moment.unix(provisioning.request_date).format("DD MMM YYYY - HH:mm")
                            : "-"}
                        </td>
                        <td className="text-nowrap">
                          {provisioning.completion_date
                            ? moment
                                .unix(provisioning.completion_date)
                                .format("DD MMM YYYY - HH:mm")
                            : "-"}
                        </td>
                        <td>
                          {provisioning.partner.name} ({provisioning.partner.code})
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {meta.count > meta.page_size && (
                  <Pagination
                    totalpage={Math.ceil(meta.count / meta.page_size)}
                    selectedpage={meta.page}
                    clickpage={(page) => gotoPage(page)}
                    next={() => gotoPage(meta.page + 1)}
                    prev={() => gotoPage(meta.page - 1)}
                  />
                )}
              </div>
            </Box>
          </div>
        </div>
      </div>

      <ModalDetailProvisioning
        show={modal === "detail"}
        onClose={() => {
          setModal(null);
        }}
        provisioning={selected}
      />
    </MainLayout>
  );
}

function CheckProvisioningBtn({ id, onSuccess, onError }) {
  const [error, setError] = useState("");

  const [processing, setProcessing] = useState(false);

  const refreshProvisioningStatus = async (id) => {
    setProcessing(true);
    try {
      let { data } = await checkProvisioningFromPartnerInfra(id);
      onSuccess(data.message);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        if (data.message) {
          setError(data.message);
          onError(data.message);
        } else {
          setError("Something Error Happened");
          onError("Something Error Happened");
        }
      } else {
        setError("Something Error Happened");
        onError("Something Error Happened");
      }
    }
    setProcessing(false);
  };

  return (
    <>
      {error ? (
        <div className="text-danger">
          <i className="fa fa-times"></i> {error}
        </div>
      ) : null}
      <button
        className="btn btn-sm btn-warning btn-block text-nowrap"
        disabled={processing}
        onClick={() => refreshProvisioningStatus(id)}
      >
        {processing ? (
          <>
            <i className="fa fa-refresh"></i> Refresh
          </>
        ) : (
          <>
            <i className="fa fa-refresh"></i> Refresh
          </>
        )}
      </button>
    </>
  );
}

function ModalDetailProvisioning({ show, provisioning, onClose }) {
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Detail Provisioning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td>Provisioning ID</td>
              <td>{provisioning.partner_provisioning_id}</td>
            </tr>
            <tr>
              <td>Service ID</td>
              <td>{provisioning.partner_service_id}</td>
            </tr>
            <tr>
              <td>No Registrasi</td>
              <td>{provisioning.registration?.registration_number || "-"}</td>
            </tr>
            <tr>
              <td>SN Modem</td>
              <td>{provisioning.modem?.serial_number_modem || "-"}</td>
            </tr>
            <tr>
              <td>SN GPON</td>
              <td>{provisioning.modem?.serial_number_gpon || "-"}</td>
            </tr>
            <tr>
              <td>Waktu Request</td>
              <td className="text-nowrap">
                {provisioning.request_date
                  ? moment.unix(provisioning.request_date).format("DD MMM YYYY - HH:mm")
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Waktu Selesai</td>
              <td className="text-nowrap">
                {provisioning.completion_date
                  ? moment.unix(provisioning.completion_date).format("DD MMM YYYY - HH:mm")
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Nama Pelanggan</td>
              <td>{provisioning.registration?.name || "-"}</td>
            </tr>
            <tr>
              <td>No Pelanggan</td>
              <td>{provisioning.registration?.customer_number || "-"}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                <ProvisioningStatusBadge status={provisioning.status} />
              </td>
            </tr>
            <tr>
              <td>Deskripsi</td>
              <td>{provisioning.description || "-"}</td>
            </tr>
            <tr>
              <td>Tipe Provisioning</td>
              <td>{provisioning.type || "-"}</td>
            </tr>

            <tr>
              <td>Partner</td>
              <td>
                {provisioning.partner?.name} ({provisioning.partner?.code})
              </td>
            </tr>
          </tbody>
        </table>
        {provisioning.type === "activation" ? (
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>OLT</td>
                <td>{provisioning.partner_olt_id || "-"}</td>
              </tr>
              <tr>
                <td style={{ width: "150px" }}>Splitter ID</td>
                <td>{provisioning.partner_odp_splitter_id}</td>
              </tr>
              <tr>
                <td>Splitter Port</td>
                <td>{provisioning.partner_odp_splitter_port}</td>
              </tr>
              <tr>
                <td>PPPoE Username</td>
                <td>{provisioning.pppoe_username || "-"}</td>
              </tr>
              <tr>
                <td>PPPoE Password</td>
                <td>{provisioning.pppoe_password || "-"}</td>
              </tr>
              <tr>
                <td>Lat</td>
                <td>{provisioning.latitude}</td>
              </tr>
              <tr>
                <td>Long</td>
                <td>{provisioning.longitude}</td>
              </tr>
              <tr>
                <td>Mac Address</td>
                <td>{provisioning.mac_address_ont}</td>
              </tr>
            </tbody>
          </table>
        ) : null}
        {provisioning.provisioning_response ? (
          <div className="form-group">
            <label>Provisioning Request Response</label>
            <textarea
              className="form-control"
              value={JSON.stringify(provisioning.provisioning_response)}
              disabled
              rows={5}
            />
          </div>
        ) : null}
        {provisioning.provisioning_status_response ? (
          <div className="form-group">
            <label>Provisioning Status Response</label>
            <textarea
              className="form-control"
              value={JSON.stringify(provisioning.provisioning_status_response)}
              disabled
              rows={5}
            />
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
}

function ProvisioningStatusBadge({ status }) {
  const colors = ["warning", "success", "danger"];

  return (
    <span className={`badge badge-${colors[status] || "secondary"}`}>
      {statusProvisioningArr[status] || "-"}
    </span>
  );
}
